'use es6';

import { getUserInteractedWithWidget } from '../selectors/getUserInteractedWithWidget';
export function trackWidgetThreadCreation() {
  return (_dispatch, getState) => {
    if (!getUserInteractedWithWidget(getState())) {
      if (window.newrelic && window.newrelic.addPageAction && window.newrelic.setCustomAttribute) {
        window.newrelic.addPageAction('threadCreatedInWidget', {
          actionAttribute: 'createdThreadInhWidget'
        });
      }
    }
  };
}
export function trackWidgetFileAttachment() {
  return (_dispatch, getState) => {
    if (!getUserInteractedWithWidget(getState())) {
      if (window.newrelic && window.newrelic.addPageAction && window.newrelic.setCustomAttribute) {
        window.newrelic.addPageAction('fileAttachedToWidget', {
          actionAttribute: 'attachedFileToWidget'
        });
      }
    }
  };
}