import * as helpers from './common/helpers';
import * as storageKeys from './storageKeys';
const SESSION_LENGTH_IN_MILLISECONDS = 1000 * 60 * 30; // 30 minutes

const OPERATING_SYSTEMS = [{
  name: 'windows 10',
  pattern: /(Windows 10.0|Windows NT 10.0)/
}, {
  name: 'windows 8',
  pattern: /(Windows 8|Windows8.1|Windows NT 6.2|Windows NT 6.3)/
}, {
  name: 'windows 7',
  pattern: /(Windows 7|Windows NT 6.1)/
}, {
  name: 'windows vista',
  pattern: /Windows NT 6.0/
}, {
  name: 'windows xp',
  pattern: /(Windows NT 5.1|Windows XP)/
}, {
  name: 'android',
  pattern: /Android/
}, {
  name: 'linux',
  pattern: /(Linux|X11)/
}, {
  name: 'ios',
  pattern: /(iPhone|iPad|iPod)/
}, {
  name: 'mac',
  pattern: /Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh/
}];
const getDefaultHamplitudeProperties = time => ({
  device_id: helpers.makeUuid(),
  last_event_id: 0,
  last_sequence_number: 0,
  last_timestamp_checked_against_session: time,
  session_id: time
});
export const getHamplitudeProperties = getTempStorage => {
  const storageProperties = getTempStorage(storageKeys.hamplitudeKey);
  if (storageProperties) {
    try {
      return JSON.parse(storageProperties);
    } catch (err) {
      // noop
    }
  }
  return null;
};
const setHamplitudeProperties = (setTempStorage, hamplitudeProperties) => setTempStorage(storageKeys.hamplitudeKey, JSON.stringify(hamplitudeProperties));
export const refreshHamplitudeProperties = (hamplitudeProperties, {
  currentTime,
  deviceId
}) => {
  const {
    device_id,
    last_event_id = 0,
    last_sequence_number = 0,
    last_timestamp_checked_against_session = currentTime,
    session_id = currentTime
  } = hamplitudeProperties;
  const refreshed = {
    device_id,
    last_event_id,
    last_sequence_number,
    last_timestamp_checked_against_session,
    session_id
  };

  // If the current deviceId is different from the stored one
  // then we reset the Session Properties and provide new ones
  if (deviceId && deviceId !== device_id) {
    const resettedProperties = getDefaultHamplitudeProperties(currentTime);
    resettedProperties.device_id = deviceId;
    return resettedProperties;
  }
  const timeSinceSessionRefresh = currentTime - last_timestamp_checked_against_session;
  if (timeSinceSessionRefresh > SESSION_LENGTH_IN_MILLISECONDS) {
    refreshed.last_event_id = 0;
    refreshed.session_id = currentTime;
  }
  refreshed.last_event_id = last_event_id + 1;
  refreshed.last_sequence_number = last_sequence_number + 1;
  refreshed.last_timestamp_checked_against_session = currentTime;
  return refreshed;
};
const getAndRefreshHamplitudeProperties = (getTempStorage, setTempStorage, {
  currentTime,
  deviceId
}) => {
  const currentHamplitudeProperties = getHamplitudeProperties(getTempStorage) || getDefaultHamplitudeProperties(currentTime);
  const hamplitudeProperties = refreshHamplitudeProperties(currentHamplitudeProperties, {
    currentTime,
    deviceId
  });
  helpers.dispatchFunctionAsync(() =>
  // We shouldn't block the main thread for updating the localStorage with the new Session Properties
  setHamplitudeProperties(setTempStorage, hamplitudeProperties));
  return hamplitudeProperties;
};
const lookupOperatingSystem = fingerprint => {
  const match = OPERATING_SYSTEMS.find(({
    pattern
  }) => pattern.test(fingerprint));
  return match ? match.name : 'unknown';
};

// These are Properties always sent on the Event Payload
// and that have dynamic values that are recalculated on each `.track` call

export const getDynamicMetaProperties = ({
  deviceId,
  getCurrentHref,
  getNetworkType,
  getNetworkSpeed,
  getTempStorage,
  setTempStorage
}) => {
  const currentTime = Date.now();
  const hamplitudeProperties = getAndRefreshHamplitudeProperties(getTempStorage, setTempStorage, {
    currentTime,
    deviceId
  });
  const dynamicProperties = Object.assign({}, hamplitudeProperties, {
    timestamp: currentTime,
    currentPageUrl: '',
    networkType: '',
    networkSpeed: ''
  });
  dynamicProperties.currentPageUrl = helpers.truncate(getCurrentHref(), 256);
  dynamicProperties.networkType = getNetworkType();
  dynamicProperties.networkSpeed = getNetworkSpeed();
  return dynamicProperties;
};

// These are Properties always sent on the Event Payload
// and that have static value (properties defined when the client is created)

export const getStaticMetaProperties = ({
  clientName,
  getReferrer,
  getUserAgent,
  getScreenWidth,
  getScreenHeight,
  getWindowWidth,
  getWindowHeight,
  getDeployableName,
  getDeployableVersion
}) => {
  const staticProperties = {
    windowWidth: -1,
    windowHeight: -1,
    screenWidth: -1,
    screenHeight: -1,
    screenSize: '',
    lastPageUrl: '',
    howOsDetailed: '',
    singlePageAppSessionId: Date.now(),
    trackingClient: clientName || 'custom',
    deployableName: '',
    deployableVersion: ''
  };
  staticProperties.windowWidth = getWindowWidth();
  staticProperties.windowHeight = getWindowHeight();
  staticProperties.deployableName = getDeployableName();
  staticProperties.deployableVersion = getDeployableVersion();
  staticProperties.howOsDetailed = lookupOperatingSystem(helpers.between(getUserAgent(), '(', ')'));
  staticProperties.screenWidth = getScreenWidth();
  staticProperties.screenHeight = getScreenHeight();
  staticProperties.screenSize = helpers.getScreenWidthSize(getScreenWidth());
  staticProperties.lastPageUrl = helpers.truncate(getReferrer(), 256);
  return staticProperties;
};
export const getMetaProperties = (staticProperties, dynamicProperties) => {
  // metaProperties = properties that get stamped to each event
  // staticProperties = properties that get set once on tracker initialisation
  // dynamicProperties = properties that can change for each event
  const metaProperties = Object.assign({}, dynamicProperties, staticProperties);
  const emptyProperties = helpers.getObjectKeys(metaProperties).filter(key => !metaProperties[key]);

  // We cast the emptyProperties as [] since the emptyProperties are evaluated
  // during runtime, and we don't know which ones are empty during build time.
  return helpers.omit(metaProperties, emptyProperties);
};