'use es6';

import { getData } from 'conversations-async-data/async-data/operators/getters';
import { getEntries } from 'conversations-async-data/indexed-async-data/operators/getters';
import { createSelector } from 'reselect';
import { getCurrentThreadId } from '../../thread-history/selectors/getCurrentThreadId';
import { getSortedMessagesByThreadId } from '../operators/getSortedMessagesByThreadId';
import { getUnpublishedMessages } from './getUnpublishedMessages';
export const getUnpublishedMessagesForCurrentThread = createSelector([getUnpublishedMessages, getCurrentThreadId], (unpublishedMessages, threadId) => {
  const messages = getEntries(unpublishedMessages).map(getData);
  return getSortedMessagesByThreadId(messages, threadId);
});