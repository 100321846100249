import BaseReporter from './BaseReporter';
import { PageLoadMetrics } from '../Metrics';
import { isReload, isHashNavigation } from '../navigation';
export default class VisibleMarkersReporter extends BaseReporter {
  report(action) {
    switch (action.type) {
      case 'NO_VISIBLE_MARKERS':
        {
          const {
            extra: {
              scenario
            }
          } = action.payload;
          PageLoadMetrics.counter('no-visible-markers', {
            scenario,
            hasHash: String(isHashNavigation()),
            isReload: String(isReload())
          }).increment();
          break;
        }
      default:
        break;
    }
  }
}