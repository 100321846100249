'use es6';

import { handleStoreMessagesCookie } from '../../post-message/handleStoreMessagesCookie';
import { getMessagesUtk } from '../../query-params/getMessagesUtk';
import { trackInteraction } from '../../usage-tracking/actions/trackInteraction';
export function consentToCookies() {
  return dispatch => {
    handleStoreMessagesCookie(getMessagesUtk());
    dispatch(trackInteraction('widget-interaction', {
      action: 'clicked consent to cookies button'
    }));
  };
}