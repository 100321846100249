'use es6';

import get from 'transmute/get';
import { createSelector } from 'reselect';
import { getPopOpenWelcomeMessage } from '../../selectors/widgetDataSelectors/getPopOpenWelcomeMessage';
import { getIsBot } from '../../selectors/widgetDataSelectors/getIsBot';
import { getUrlForMessage } from '../../utils/getUrlForMessage';
import { widgetState } from './widgetState';
import { getLatestWidgetData } from '../../widget-data/selectors/getLatestWidgetData';
import { getVisitorIdentificationEnabled } from '../../visitor-identity/operators/getVisitorIdentificationEnabled';
import { getFeedbackSurveyEnabled } from '../../feedback-survey/operators/getFeedbackSurveyEnabled';
import { getKnowledgeBaseEnabled } from '../../widget-data/selectors/getKnowledgeBaseEnabled';
export const eventProps = createSelector([widgetState, getIsBot, getPopOpenWelcomeMessage, getLatestWidgetData, getKnowledgeBaseEnabled, getVisitorIdentificationEnabled, getFeedbackSurveyEnabled], (state, botEnabled, promptEnabled, widgetData, knowledgeBaseEnabled, visitorIdentificationEnabled, feedbackSurveyEnabled) => ({
  state,
  botEnabled,
  promptEnabled,
  path: getUrlForMessage(),
  chatflowId: get('chatflowId', widgetData),
  knowledgeBaseEnabled,
  visitorIdentificationEnabled,
  feedbackSurveyEnabled
}));