'use es6';

import { createSelector } from 'reselect';
import { getLatestWidgetData } from '../../widget-data/selectors/getLatestWidgetData';
import { getAPIEnableWidgetCookieBanner } from '../../widget-ui/selectors/getAPIEnableWidgetCookieBanner';
import { gdprCookieConsentOnWidgetLoadEnabled } from '../../utils/gdprCookieConsentPromptHelpers';
import { getCookieBannerOnExitVisible } from '../../visitor-identity/operators/getCookieBannerOnExitVisible';
import { getHasMessagesCookieBeenSaved } from '../../gdpr/selectors/getHasMessagesCookieBeenSaved';
export const shouldRequireExplicitCookieConsent = createSelector([getLatestWidgetData, getHasMessagesCookieBeenSaved, getAPIEnableWidgetCookieBanner, getCookieBannerOnExitVisible], (widgetData, hasMessagesCookieBeenSaved, shouldEnableWidgetCookieBannerFromAPI, cookieBannerOnExitVisible) => {
  const gdprCookieConsentOnWidgetLoadIsEnabled = gdprCookieConsentOnWidgetLoadEnabled(widgetData);
  const showCookieConsentBeforeChatting = shouldEnableWidgetCookieBannerFromAPI || gdprCookieConsentOnWidgetLoadIsEnabled;
  return Boolean(showCookieConsentBeforeChatting || cookieBannerOnExitVisible) && !hasMessagesCookieBeenSaved;
});