'use es6';

import { connect } from 'react-redux';
import { getChatHeadingConfig } from '../../chat-heading-config/selectors/getChatHeadingConfig';
import { getChatHeadingResponders } from '../../responders/selectors/getChatHeadingResponders';
import { getIsMobile } from '../../selectors/getIsMobile';
import { getInitialMessageText } from '../../selectors/widgetDataSelectors/getInitialMessageText';
import { closeInitialMessageBubble } from '../actions/closeInitialMessageBubble';
import { threadHistoryToMessageList } from 'conversations-message-history/thread-history/operators/threadHistoryToMessageList';
import InitialMessageBubble from 'conversations-visitor-experience-components/visitor-widget/InitialMessageBubble';
import { showQuickReplyOutsideChat } from '../../utils/showQuickRepliesOutsideChat';
const mapStateToProps = state => {
  const messageRecords = threadHistoryToMessageList(state.stagedThread.data);
  const displayBotQuickRepliesOutsideChat = showQuickReplyOutsideChat(state);
  const initialMessage = displayBotQuickRepliesOutsideChat ? messageRecords.reduce((acc, msg) => acc += ` ${msg.text}`, '') : getInitialMessageText(state);
  return {
    chatHeadingConfig: getChatHeadingConfig(state),
    chatHeadingResponders: getChatHeadingResponders(state),
    initialMessage,
    mobile: getIsMobile(state)
  };
};
const mapDispatchToProps = {
  onClose: closeInitialMessageBubble
};
export default connect(mapStateToProps, mapDispatchToProps)(InitialMessageBubble);