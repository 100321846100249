import { CONSENT_TO_PROCESS_CLOSED } from '../constants/ActionTypes';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import { createAction } from 'flux-actions';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
import { trackInteraction } from '../../usage-tracking/actions/trackInteraction';
export const closedConsentToProcess = createAction(CONSENT_TO_PROCESS_CLOSED);
export function closeConsentToProcess() {
  return dispatch => {
    dispatch(trackInteraction('widget-interaction', {
      action: 'close consent to process'
    }));
    dispatch(closedConsentToProcess());
  };
}