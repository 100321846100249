//@ts-expect-error untyped file
import { getShowInitialMessageBubble } from '../initial-message-bubble/selectors/getShowInitialMessageBubble';
import { getIsBot } from '../quick-reply/operators/getIsBot';
import { getShowQuickReplyOutsideChatGate } from '../quick-reply/operators/getShowQuickReplyOutsideChatGate';
//@ts-expect-error untyped file
import { getSelectedThread } from '../selected-thread/selectors/getSelectedThread';
//@ts-expect-error untyped file
import { getQuickReplyAllowMultiSelect } from 'conversations-message-history/common-message-format/operators/cmfQuickReplyGetters';
//@ts-expect-error untyped file
import { hasUnansweredQuickReplyMessage } from '../selectors/chatSelectors';

// @ts-expect-error dependency missing types
import { threadHistoryToMessageList } from 'conversations-message-history/thread-history/operators/threadHistoryToMessageList';
// @ts-expect-error dependency missing types
import { getPubSubMessage } from 'conversations-message-history/unpublished-messages/operators/getPubSubMessage';
import { getDisplayBotQuickRepliesOutsideChat } from '../selectors/widgetDataSelectors/getDisplayBotQuickRepliesOutsideChat';
export const showQuickReplyOutsideChat = state => {
  const selectedThread = getSelectedThread(state);
  const messageRecords = threadHistoryToMessageList(state.stagedThread.data);
  const message = getPubSubMessage(messageRecords.last());
  const allowMultiSelect = getQuickReplyAllowMultiSelect(message);
  const displayBotQuickRepliesOutsideChat = getDisplayBotQuickRepliesOutsideChat(state);
  return getShowInitialMessageBubble(state) && getShowQuickReplyOutsideChatGate(state) && getIsBot(state) && hasUnansweredQuickReplyMessage(state, {
    thread: selectedThread,
    searchOnlyForInitialMessage: true
  }) && displayBotQuickRepliesOutsideChat && !allowMultiSelect;
};