import * as ActionTypes from '../constants/feedbackSurveyActionTypes';
// @ts-expect-error untyped file
import { createAction } from 'flux-actions';
import { fetchFeedbackSurveyEnabled } from '../clients/fetchFeedbackSurvey';
import Raven from 'raven-js';
const updateFeedbackSurveyEnabled = createAction(ActionTypes.UPDATE_FEEDBACK_SURVEY_ENABLED, enabled => ({
  enabled
}));
export function fetchFeedbackSurvey(id, sessionId) {
  return dispatch => {
    fetchFeedbackSurveyEnabled({
      id,
      sessionId
    }).then(({
      enabled
    }) => {
      dispatch(updateFeedbackSurveyEnabled(enabled));
    }).catch(error => {
      Raven.captureException(error);
    });
  };
}