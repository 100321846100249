'use es6';

import { trackInteraction } from '../../usage-tracking/actions/trackInteraction';
export const trackSendMessage = ({
  threadId
}) => dispatch => {
  const eventProps = {};
  if (typeof threadId === 'number') {
    eventProps.threadId = threadId;
  }
  dispatch(trackInteraction('send-message', eventProps));
};