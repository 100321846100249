'use es6';

import PortalIdParser from 'PortalIdParser';
import noAuthHttp from '../../http/noAuthApiClient';
export function createVisitorThread({
  sessionId,
  messageId,
  hubspotUtk,
  messagesPageUri,
  visitorThreadInitialHistory,
  zoneId
}) {
  return noAuthHttp.post(`livechat-public/v1/thread/visitor`, {
    query: {
      sessionId,
      welcomeMessageId: messageId,
      hubspotUtk,
      portalId: PortalIdParser.get(),
      expectedResponseType: 'WRAPPER_V2',
      zoneId
    },
    data: {
      visitorThreadInitialHistory
    },
    headers: {
      'X-HubSpot-Messages-Uri': messagesPageUri
    }
  });
}