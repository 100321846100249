// @ts-expect-error untyped file
import noAuthHttp from '../../http/noAuthApiClient';
export const getToken = ({
  sessionId,
  hubspotUtk,
  isUngatedForNewClientIdFormat
}) => noAuthHttp.get('livechat-public/v1/pubsub/token/visitor', {
  query: {
    sessionId,
    hubspotUtk,
    isUngatedForNewClientIdFormat
  }
});